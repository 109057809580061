import React, { useEffect, useState } from 'react';
import Layout, { Content } from '../../components/Layout';
import { useAuth } from '../../hooks/useAuth';
import { useApplication } from '../../hooks/useApplication';
import Navigation from '../../components/mybunac/navigation';
import ApplicationPayment from '../../components/mybunac/Application/Payment';
import styled from 'styled-components';

const ContentLayout = styled.div`
  display: block;
  height: 100%;
`;

const Payment = ({ data, pageContext }) => {
  const { isAuthenticated } = useAuth();
  const { application, setApplicationId } = useApplication();
  const [selectedProducts, setSelectedProducts] = useState([]);

  useEffect(() => {
    if (!isAuthenticated) {
      return;
    }
    const applicationId = window.location.hash.substr(1);
    setApplicationId(applicationId);

    // eslint-disable-next-line
  }, [isAuthenticated]);

  useEffect(() => {
    const { products } = application || { products: [] };
    const selectedProducts = products.filter(({ selected }) => selected);
    setSelectedProducts(selectedProducts);
  }, [application]);

  return (
    <Layout title={'Payment'}>
      <Navigation />
      <Content>
        <ContentLayout>
          <ApplicationPayment
            application={application}
            products={selectedProducts}
          />
        </ContentLayout>
      </Content>
    </Layout>
  );
};

export default Payment;